<template>
  <div>
    <!-- Process table -->
    <div class="card"
         v-if="isGranted([permissions.ACTIVITY_PROCESS_VIEW, permissions.ACTIVITY_PROCESS_VIEW_OWN], activity)"
         qid="processes-section"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <h5 class="float-left" qid="processes-table-title">
              {{ $t('activities.process.title') }}
            </h5>
          </div>
          <div class="col-sm-6 text-right">
            <button class="btn btn-success ml-3 pl-3 pr-3"
                    @click="showCreateSModal()"
                    qid="processes-button-create"
                    v-if="isGranted(permissions.ACTIVITY_PROCESS_CREATE, activity)"
            >
              <font-awesome-icon icon="plus"/> {{ $t('activities.process.create_button') }}
            </button>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
          <tr>
            <th scope="col">
              {{ $t('processes.table.name') }}
            </th>
            <th scope="col">
              {{ $t('processes.table.owners') }}
            </th>
            <th scope="col">
              {{ $t('processes.table.tags') }}
            </th>
            <th scope="col">
              {{ $t('processes.table.process_group') }}
            </th>
            <th scope="col">
              {{ $t('system.last_updated') }}
            </th>
            <th width="100"></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="object in tableItems">
              <td>
                <router-link
                    :to="{ name: 'processes.view', params: { slug: object.slug }}"
                    qid="p-view-page-link"
                >
                  {{object.name}}
                </router-link>
              </td>
              <td>
                <span class="mr-1" v-for="user in getReducedUsers(object.users)">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
                <span v-if="object.users.length > 3"
                      class="badge cursor-pointer"
                      @click="openUsersModal(object)"
                >
                  <font-awesome-icon icon="users"/>
                  + {{ object.users.length - 3 }}
                </span>
              </td>
              <td>
                <span class="mr-1" v-for="tag in getReducedTags(object.tags_translated)" qid="p-tags-list">
                  <span class="badge mb-1" :title="tag.name_translated">
                    <font-awesome-icon icon="tag"/>
                    {{tag.name_translated | truncate(35)}}
                  </span>
                </span>
                <span v-if="object.tags_translated.length > 3"
                      class="badge cursor-pointer"
                      @click="openTagsModal(object)"
                >
                  <font-awesome-icon icon="tags"/>
                  + {{ object.tags_translated.length - 3 }}
                </span>
              </td>
              <td>
                <span v-if="object.process_group"
                      class="badge"
                >
                  <font-awesome-icon icon="project-diagram"/>
                  {{ object.process_group.name }}
                </span>
              </td>
              <td>
                <span class="text-normal">
                  {{ object.updated_at | formatDate }}
                </span>
              </td>
              <td align="right">
                <span v-if="isGranted(permissions.ACTIVITY_PROCESS_DELETE, activity)"
                      class="btn btn-delete"
                      qid="delete-p-modal"
                      @click="showConfirmDeleteModal(object)"
                >
                  <font-awesome-icon icon="trash-alt" />
                </span>
              </td>
            </tr>
            <tr v-if="!sLoading && !tableItems.length" qid="no-results-row" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


    <!-- Delete process modal template -->
    <b-modal ref="delete-process" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-ac-title">
          {{ $t('activities.process.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('activities.process.delete.description') }}
        <span v-if="itemToDelete">{{ itemToDelete.name }}</span>
      </h5>
      <h5 class="text-center">
        {{ $t('activities.process.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideSConfirmDeleteModal"
                  qid="delete-ac-cancel-button"
        >
          {{ $t('activities.process.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeleteS(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-ac-submit-button"
        >
          {{ $t('activities.process.delete.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Add new process modal -->
    <b-modal ref="add-process" hide-footer @hide="hideCreateSModal">
      <div class="d-block text-center">
        <h4 qid="add-ac-title">
          {{ $t('activities.process.create.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="sFormSubmit" qid="create-ac-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-ac-pg-label">
              {{ $t('activities.process.create.process_label') }}
            </label>
            <multiselect
                v-model="selectedP"
                :options="pItems"
                :placeholder="$t('activities.process.create.process_placeholder')"
                label="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @search-change="loadS"
                track-by="slug"
                qid="create-ac-form-ac"
            ></multiselect>
          </b-form-group>
          <div v-if="selectedP && selectedP.description"
               class="text-center mb-3 mt-4"
          >
            <span class="cursor-pointer">
              <span class="mr-2"
                    @click="showSDescription = !showSDescription"
                    qid="expand-process-description"
              >
                {{$t('activities.process.create.process_description_expand')}}
              </span>
              <font-awesome-icon icon="chevron-down" v-if="!showSDescription"/>
              <font-awesome-icon icon="chevron-up" v-if="showSDescription"/>
            </span>
          </div>
          <div v-if="selectedP && selectedP.description && showSDescription" class="mb-4 mt-2">
            <span class="text-formatted">
              {{ selectedP.description }}
            </span>
          </div>
          <div v-if="selectedP && selectedP.tags_translated && selectedP.tags_translated.length">
            <label class="mt-3">
                {{ $t('activities.process.create.tags_label') }}
            </label>
            <br>
            <span class="mr-1" v-for="tag in selectedP.tags_translated" qid="p-tags-list">
              <span class="badge badge-info mb-1" :title="tag.name_translated">
                {{tag.name_translated | truncate(35)}}
              </span>
            </span>
          </div>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideCreateSModal"
                    qid="create-ac-cancel-button"
          >
            {{ $t('activities.process.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    :disabled="buttonDisabled"
                    variant="success"
                    qid="create-ac-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('activities.process.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>

    <ShowMoreModal
        :items="selectedItem.tags_translated"
        :show="showTagsModal"
        @closeViewModal="closeTagsModal"
        type="tags"
    ></ShowMoreModal>

    <ShowMoreModal
        :items="selectedItem.users"
        :show="showUsersModal"
        @closeViewModal="closeUsersModal"
        type="users"
    ></ShowMoreModal>

  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import ShowMoreModal from '@/components/ShowMoreModal.vue';
  import Avatar from '@/components/Avatar.vue';

  export default {
    name: 'ProcessTable',
    data: function() {
      return {
        sForm: {
          system_role: '',
          rules_followed: '',
          data_objects: []
        },

        // Process
        tableItems: [],
        selectedP: [],
        viewSModal: false,
        selectedSSort: '',
        selectedSDirection: '',
        pItems: [],
        sLoading: true,

        error: '',
        loadingItems: false,
        itemToDelete: {},
        itemObject: {},
        selectedP: [],
        showSDescription: false,
        buttonDisabled: false,
        selectedItem: {},
        showTagsModal: false,
        showUsersModal: false,
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      }
    },
    components: {
      Avatar,
      ShowMoreModal
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadS();
      }
    },
    methods: {
      showCreateSModal() {
        this.$refs['add-process'].show()
      },
      openTagsModal(object) {
        this.selectedItem = object
        this.showTagsModal = true
      },
      closeTagsModal() {
        this.selectedItem = {}
        this.showTagsModal = false
      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      getReducedTags(tags) {
        let reducedTags = []
        if (tags.length > 3) {
          reducedTags = _.cloneDeep(tags);
          reducedTags = reducedTags.slice(0, 3);
        } else {
          reducedTags = tags;
        }
        return reducedTags;
      },
      getReducedUsers(users) {
        let reducedUsers = []
        if (users.length > 3) {
          reducedUsers = _.cloneDeep(users);
          reducedUsers = reducedUsers.slice(0, 3);
        } else {
          reducedUsers = users;
        }
        return reducedUsers;
      },
      hideCreateSModal() {
        this.error = ''
        this.selectedP = []
        this.loadContent()
        this.$refs['add-process'].hide()
      },
      showConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-process'].show()
      },
      hideSConfirmDeleteModal() {
        this.itemToDelete = {}
        this.loadContent()
        this.$refs['delete-process'].hide()
      },
      openViewSModal(object) {
        this.selectedP = object
        this.viewSModal = true
      },
      handleCloseViewSModal () {
        this.selectedP = []
        this.viewSModal = false;
      },
      confirmDeleteS(object) {
        let self = this;
        piincHttp.delete('activities/' + this.$route.params.slug + '/processes/' + object.slug).then(function() {
          self.hideSConfirmDeleteModal();
          self.loadProcesses();
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('activities.process.delete.success'))
        }, function() {});
      },
      sFormSubmit(evt) {
        this.buttonDisabled = true;
        let formData = new FormData();

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        formData.append("process", this.selectedP.slug);
        formData.append("activity", this.$route.params.slug);

        piincHttp.post('/activities/' + this.$route.params.slug + '/processes', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function(response) {
          self.$toastr('success', self.$t('activities.process.create.success'))
          self.hideCreateSModal();
          self.loadProcesses();
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      loadContent() {
        let self = this;
        piincHttp.get('activities/' + this.$route.params.slug, { params:
          {
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.activity = response.data;
        }, function() {});
      },
      loadProcesses() {
        let self = this;
        piincHttp.get('activities/' + this.$route.params.slug + '/processes', { params:
          {
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.tableItems = response.data;
          self.sLoading = false;
        }, function() {});
      },
      loadS(query) {
        let self = this;
        piincHttp.get('processes', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.pItems = response.data.items
          if (self.pItems && !self.pItems.length && !query) {
            self.pItems.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      }
    },
    created () {
      this.activity = this.object;
      if (this.getClient && this.getClient.slug) {
        this.loadS();
      }
      // Table items
      this.loadProcesses();
    }
  }
</script>
