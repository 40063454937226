<template>
  <div>
    <div v-if="!show404 && !show403">
      <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

      <BasicInfoBlock :object="activity" @updated="updateActivity" :edit="edit"></BasicInfoBlock>

      <b-tabs class="mt-3" v-model="tabIndex">

        <!-- Basic info tab -->
        <b-tab :title="$t('activities.tab.basic_info')" active @click="basicTabClicked">

          <br />
          <!-- Description block -->
          <DescriptionBlock :object="activity" @updated="updateActivity"></DescriptionBlock>
          <br />
          <!-- Organization list -->
          <OrganizationsTable :object="activity" @updated="updateActivity"></OrganizationsTable>
          <br />
          <!-- Policies list -->
          <PoliciesAndProceduresTable :object="activity" @updated="updateActivity" v-if="getClient.policyModule"></PoliciesAndProceduresTable>
        </b-tab>

        <!-- Data mapping tab -->
        <b-tab :title="$t('activities.tab.data_mapping')" class="mt-3 data-mapping-tab" @click="dataTabClicked">
          <div v-if="showDataTab">
            <!-- Mapping description block -->
            <MappingDescriptionBlock :object="activity" @updated="updateActivity"></MappingDescriptionBlock>
            <br>
            <SystemTable
                :object="activity"
                v-if="showProcessTable && getClient.systemModule"
                @updated="updateActivity"
            ></SystemTable>
            <br>
            <ThirdPartySourcesTable
                :object="activity"
                v-if="showTPSTable && getClient.contractBasicModule && getClient.gdprAdvancedModule"
                @updated="updateActivity"
            ></ThirdPartySourcesTable>
            <br>
            <ThirdPartyReceiversTable
                :object="activity"
                v-if="showTPRTable && getClient.contractBasicModule && getClient.gdprModule"
                @updated="updateActivity"
            ></ThirdPartyReceiversTable>
          </div>
        </b-tab>

        <!-- GDPR tab -->
        <b-tab :title="$t('activities.tab.gdpr')" class="mt-3 gdpr-tab" @click="gdprTabClicked" v-if="getClient.gdprModule && activity.privacy_feature">
          <div v-if="showGdprTab">
            <ProcessingRole :object="activity" @updated="updateActivity"></ProcessingRole>
            <br>
            <DataSubjectsTable :object="activity" @updated="updateActivity"></DataSubjectsTable>
            <br>
            <RetentionRulesTable :object="activity" @updated="updateActivity"></RetentionRulesTable>
            <br>
            <IntegrityCheck :object="activity" @updated="updateActivity"></IntegrityCheck>
            <br>
            <MeasuresTable :object="activity" @updated="updateActivity"></MeasuresTable>
            <br>
            <ProcessingType :object="activity" @updated="updateActivity" v-if="getClient.gdprAdvancedModule"></ProcessingType>
          </div>
        </b-tab>

        <!-- Risk scenarios tab -->
        <b-tab :title="$t('activities.tab.risk_management')" class="mt-3" @click="riskTabClicked" v-if="getClient.riskModule">
          <div v-if="showRiskTab">
            <RiskScenariosTable :object="activity" @updated="updateActivity"></RiskScenariosTable>
          </div>
        </b-tab>

        <!-- Activity assesments tab -->
        <b-tab :title="$t('systems.tab.assessments')" class="mt-3" @click="assessmentsTabClicked" v-if="getClient.assessmentModule && activity.assessment_feature">
          <AssessmentsTable :activity="activity" @updated="updateActivity"></AssessmentsTable>
        </b-tab>

        <!-- Activity processes tab -->
        <b-tab :title="$t('activities.tab.processes')" class="mt-3" @click="processTabClicked">
          <div v-if="showProcessTab">
            <ProcessTable :object="activity" @updated="updateActivity"></ProcessTable>
          </div>
        </b-tab>

        <!-- System history tab -->
        <b-tab :title="$t('activities.tab.history')" class="mt-3" @click="historyTabClicked">
          <div v-if="showHistoryTab">
            <ActivityHistory :object="activity"></ActivityHistory>
          </div>
        </b-tab>

      </b-tabs>
    </div>

    <div v-if="show404">
      <page404></page404>
    </div>

    <div v-if="show403">
      <page403></page403>
    </div>

  </div>
</template>

<script>
import piincHttp from '@/util/configureAxios.js'
import {config} from '@/config.js'
import ActivityEditForm from '@/views/Activities/ActivityEditForm.vue';
import Avatar from '@/components/Avatar.vue';
import DataSubjectsTable from '@/views/Activities/DataSubjectsTable.vue';
import DescriptionBlock from '@/views/Activities/DescriptionBlock.vue';
import MappingDescriptionBlock from '@/views/Activities/MappingDescriptionBlock.vue';
import PoliciesAndProceduresTable from '@/views/Activities/PoliciesAndProceduresTable.vue';
import BasicInfoBlock from '@/views/Activities/BasicInfoBlock.vue';
import OrganizationsTable from '@/views/Activities/OrganizationsTable.vue';
import RetentionRulesTable from '@/views/Activities/RetentionRulesTable.vue';
import SystemTable from '@/views/Activities/SystemTable.vue';
import ProcessTable from '@/views/Activities/ProcessTable.vue';
import ThirdPartySourcesTable from '@/views/Activities/ThirdPartySourcesTable.vue';
import ThirdPartyReceiversTable from '@/views/Activities/ThirdPartyReceiversTable.vue';
import DataObjectCreateForm from '@/views/DataObjects/DataObjectCreateForm.vue';
import IntegrityCheck from '@/views/Activities/IntegrityCheck.vue';
import MeasuresTable from '@/views/Activities/MeasuresTable.vue';
import ProcessingType from '@/views/Activities/ProcessingType.vue';
import ProcessingRole from '@/views/Activities/ProcessingRole.vue';
import RiskScenariosTable from '@/views/Activities/RiskScenariosTable.vue';
import ActivityHistory from '@/views/Activities/ActivityHistory.vue';
import ValidationList from '@/views/Validations/ValidationList.vue';
import ValidationForm from '@/views/Validations/ValidationForm.vue';
import ShowMoreModal from '@/components/ShowMoreModal.vue';
import page404 from '@/components/404block.vue';
import page403 from '@/components/403block.vue';
import AssessmentsTable from '@/views/Activities/AssessmentsTable.vue';

export default {
  name: 'ActivityView',
  data: function() {
    return {
      breadcrumbItems: [
        {
          text: this.$t('dashboard.title'),
          to: '/'
        },
        {
          text: this.$t('processes.title'),
          to: '/processing-activities'
        }
      ],
      descriptionLength: config.descriptionLength,
      showFullDescription: false,
      activity: {},
      edit: this.$route.params.edit,
      selectedSort: '',
      selectedDirection: '',
      lbTableItems: [],
      lbLoading: true,

      itemToDelete: {},
      tags: [],
      users: [],
      error: '',
      lbForm: {
        name: '',
        article_reason: '',
        links: []
      },
      inputLink: '',
      selectedFiles: [],
      showDescriptionInput: false,
      showMappingDescriptionInput: false,
      showPurposeInput: false,
      activityEditDescription: '',
      activityEditMappingDescription: '',

      // Tabs
      showGdprTab: false,
      showDataTab: false,
      showProcessTab: false,
      showProcessTable: false,
      showTPSTable: false,
      showTPRTable: false,
      showHistoryTab: false,
      showRiskTab: false,
      showAssessmentsTab: false,

      buttonDisabled: false,
      showTaskToolbar: false,
      activeTask: 0,
      tabIndex: 0,
      myTasks: [],
      myGuides: [],
      validationShow: false,
      updateValidationList: false,
      validation: {},
      validationOptions: [],
      selectedItem: {},
      showTagsModal: false,
      show404: false,
      show403: false,
    }
  },
  computed: {
    getClient() {
      return this.$store.state.client
    },
    getTabIndex() {
      return this.$store.state.tabIndex
    },
    getFiles() {
      return this.selectedFiles
    },
    getReloadContent() {
      return this.$store.state.updateContent
    },
    getReloadPage() {
      return this.$store.state.updatePage
    }
  },
  watch: {
    // Watch when client changes in store and trigger api reload
    getClient: function (newValue, oldValue) {
      if (newValue && oldValue && newValue.slug && oldValue.slug && newValue.slug !== oldValue.slug) {
        let self = this;
        setTimeout(function() {
          if (self.$route.name == 'activities.view') {
            self.goToList()
          }
        }, 800);
      } else {
        this.loadContent(true);
      }
    },
    getReloadContent: function () {
      if (!this.showOnTask) {
        if (this.$store.state.updatedObject && this.$store.state.updatedObject.id) {
          if (this.$store.state.updatedObject.id == this.activity.id && this.$store.state.updatedObject.who_am_i == this.activity.who_am_i) {
            this.activity = this.$store.state.updatedObject
          }
        }
      }
    },
    getReloadPage: function () {
      if (this.$store.state.updatedObject && this.$store.state.updatedObject.id && this.$store.state.updatedObject.id == this.activity.id && this.$store.state.updatedObject.who_am_i == this.activity.who_am_i) {
        this.$router.push({ name: 'activities.view', params: { slug: this.$store.state.updatedObject.slug }, query: { task: this.$route.query.task }})
      } else {
        this.$router.push({ name: 'activities.view', params: { slug: this.activity.slug }, query: { task: this.$route.query.task }})
      }
    },
    getTabIndex: function (newValue, oldValue) {
      this.handleUpdateTab(newValue)
    }
  },
  components: {
    ActivityEditForm,
    Avatar,
    DataSubjectsTable,
    DescriptionBlock,
    MappingDescriptionBlock,
    PoliciesAndProceduresTable,
    OrganizationsTable,
    RetentionRulesTable,
    DataObjectCreateForm,
    IntegrityCheck,
    MeasuresTable,
    ProcessingType,
    ProcessingRole,
    SystemTable,
    ProcessTable,
    ThirdPartyReceiversTable,
    ThirdPartySourcesTable,
    RiskScenariosTable,
    ActivityHistory,
    ValidationList,
    ValidationForm,
    ShowMoreModal,
    AssessmentsTable,
    BasicInfoBlock,
    page403,
    page404
  },
  methods: {
    availableTabs(activity) {
      let self = this;

      return [
        { code: 'basic-info', visible: true },
        { code: 'data-flow', visible: true },
        { code: 'gdpr', visible: this.getClient.gdprModule && activity.privacy_feature },
        { code: 'risk-management', visible: this.getClient.riskModule },
        { code: 'assessments', visible: this.getClient.assessmentModule && activity.assessment_feature},
        { code: 'processes', visible: true },
        { code: 'history', visible: true },
      ].filter(tab => tab.visible);
    },
    setTabFromQuery(tabCode, activity) {

      const visibleTabs = this.availableTabs(activity);

      // Find the index of the tab with the matching code
      const tab = visibleTabs.find(tab => tab.code === tabCode);
      const tabIndex = visibleTabs.findIndex(tab => tab.code === tabCode);

      if (tab && tab.code == 'gdpr') {
        this.tabIndex = tabIndex
        this.gdprTabClicked()
      }
      if (tab && tab.code == 'assessments') {
        this.tabIndex = tabIndex
        this.assessmentsTabClicked()
      }
      if (tab && tab.code == 'risk_management') {
        this.tabIndex = tabIndex
        this.riskTabClicked()
      }
      if (tab && tab.code == 'data-flow') {
        this.tabIndex = tabIndex
        this.dataTabClicked()
      }
      if (tab && tab.code == 'history') {
        this.tabIndex = tabIndex
        this.historyTabClicked()
      }
      if (tab && tab.code == 'processes') {
        this.tabIndex = tabIndex
        this.processTabClicked()
      }
      if (tab && tab.code == 'basic-info') {
        this.tabIndex = tabIndex
        this.basicTabClicked()
      }

      console.log("Tab Index Set To:", this.tabIndex);

    },
    setValidation(object) {
      this.validation = object
    },
    handleValidationModalClose() {
      this.updateValidationList = true
      this.loadContent()
    },
    openTagsModal(object) {
      this.selectedItem = object
      this.showTagsModal = true
    },
    closeTagsModal() {
      this.selectedItem = {}
      this.showTagsModal = false
    },
    getReducedTags(tags) {
      let reducedTags = []
      if (tags.length > 3) {
        reducedTags = _.cloneDeep(tags);
        reducedTags = reducedTags.slice(0, 3);
      } else {
        reducedTags = tags;
      }
      return reducedTags;
    },
    goToList() {
      this.$router.push('/processing-activities')
    },
    getTask(code) {
      let existingTask = _.find(this.myTasks, function(object) {
        return object.code == code
      });
      return existingTask
    },
    loadTasks(code) {
      let self = this;
      if (this.getClient.projectModule) {
        piincHttp.get('projects/me/tasks', { params:
          {
            per_page: 1,
            status: 'pending',
            model_type: this.activity.who_am_i,
            model_id: this.activity.id,
            code: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myTasks.push(response.data.items[0])
          }
        }, function() {});
      }
    },
    openToolbar(object) {
      this.$store.commit('storeTaskToolbarState', true);
      this.$store.commit('storeActiveTask', object);
    },
    openHelpCenter(object) {
      let topElement = document.getElementById("app");
      if (topElement.classList.contains("guide-collapsed")) {
        topElement.classList.toggle("guide-collapsed");
      }
      if (!topElement.classList.contains("activity-collapsed")) {
        topElement.classList.toggle("activity-collapsed");
      }

      this.$store.commit('storeHelpCenterState', true);
      this.$store.commit('storeActiveGuideScope', object);
    },
    handleUpdateTab(response) {
      if (response == 'data-mapping') {
        this.tabIndex = 1
        this.dataTabClicked()
      }
      if (response == 'gdpr') {
        this.tabIndex = 2
        this.gdprTabClicked()
      }
      if (response == 'risk-management') {
        let index = 3;

        if (!this.getClient.gdprAdvancedModule) {
          index = index - 1;
        }

        this.tabIndex = index
        this.riskTabClicked()
      }
      if (response == 'basic-info') {
        this.tabIndex = 0
        this.basicTabClicked()
      }
    },
    updateActivity() {
      this.loadContent();
    },
    historyTabClicked() {
      this.showAssessmentsTab = false;
      this.showHistoryTab = true;
      this.showRiskTab = false;
    },
    basicTabClicked() {
      this.showAssessmentsTab = false;
      this.showHistoryTab = false;
      this.showRiskTab = false;
    },
    gdprTabClicked() {
      this.showAssessmentsTab = false;
      this.showGdprTab = true;
      this.showHistoryTab = false;
      this.showRiskTab = false;
    },
    riskTabClicked() {
      this.showAssessmentsTab = false;
      this.showRiskTab = true;
      this.showHistoryTab = false;
    },
    dataTabClicked() {
      this.showAssessmentsTab = false;
      let self = this
      this.showHistoryTab = false;
      this.showDataTab = true;
      self.showProcessTable = true;
      self.showTPSTable = true;
      self.showTPRTable = true;
      this.showRiskTab = false;
    },
    processTabClicked() {
      this.showAssessmentsTab = false;
      this.showProcessTab = true;
      this.showHistoryTab = false;
      this.showRiskTab = false;
    },
    assessmentsTabClicked() {
      this.showAssessmentsTab = true;
      this.showHistoryTab = false;
      this.showRiskTab = false;
    },
    downloadMedia(object){
      piincHttp.get('documents/' + object.id + '/download', {responseType: 'blob'}).then(function(response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = object.file_name;

        link.click();
        link.remove()
        window.URL.revokeObjectURL(url);
      }, function() {});
    },
    handleLink() {
      // If there's a link in input, add it to links array
      if (this.inputLink !== '') {
        if (!this.inputLink.startsWith('http')) {
          this.inputLink = 'http://' + this.inputLink
        }
        this.lbForm.links.push(this.inputLink)
        // Reset link input form
        this.inputLink = ''
      }
    },
    removeLinkItem(index) {
      // Remove link item from the links array
      this.lbForm.links.splice(index, 1);
    },
    reloadContent(object) {
      // If slug is not changed, just reload content
      if (object.slug == this.activity.slug) {
        // Remove last item in breadcrumbs, because it will be added again on load content
        this.breadcrumbItems.pop();
        this.loadContent(true);
        this.$emit('updated', object);
      } else {
        this.$store.commit('storeUpdateTasks', true);
        // If slug changed, reload state with new param
        this.$router.push({ name: 'activities.view', params: { slug: object.slug }})
      }
    },
    getUserAvatar(user){
      if (user && user.avatar) {
        return user.avatar;
      } else {
        return require('@/assets/avatar.png');
      }
    },
    getGuide(code) {
      let existingGuide = _.find(this.myGuides, function(object) {
        return object.scope == code && object.type == 'Activity' && object.enabled
      });
      return existingGuide
    },
    loadContent(breadcrumbPush) {
      let self = this;
      piincHttp.get('activities/' + this.$route.params.slug, { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.activity = response.data;
        self.validationOptions = self.activity.proportionalityValidationOptions;
        self.loadTasks('update_basic_info');
        if (breadcrumbPush) {
          // Push Third party name in breadcrumb array
          self.breadcrumbItems.push({text: self.activity.name, active: true});
        }
        setTimeout(function(){
          self.setTabFromQuery(self.$route.query.tab, self.activity);
        }, 1000);
      }, function(error) {
        if (error.status == 404) {
          self.show404 = true
        }
        if (error.status == 403) {
          self.show403 = true
        }
      });
    },
    loadGuides(code) {
      let self = this;
      let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

      piincHttp.get('guides', { params:
        {
          per_page: 1,
          language: 'en',
          type: 'Activity',
          scope: code,
          client: this.getClient.slug
        }
      }).then(function(response) {
        if (response.data.items.length) {
          self.myGuides.push(response.data.items[0])
        }
      }, function() {});
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('storeTabIndex', '');
    next()
  },
  created () {
    let self = this;
    if (this.getClient && this.getClient.slug) {
      this.loadContent(true);
    }

    if (this.$route.params.tabIndex && this.$route.params.validation) {
      setTimeout(function(){
        self.tabIndex = self.$route.params.tabIndex
        if (self.tabIndex == 1) {
          self.dataTabClicked()
        }
        if (self.tabIndex == 2) {
          self.gdprTabClicked()
        }
        if (self.tabIndex == 3) {
          self.riskTabClicked()
        }
      }, 500);
      setTimeout(function(){
        var element = document.getElementsByClassName(self.$route.params.validation);
        element[0].scrollIntoView(true);
        window.scrollBy(0, -100);
        element[0].classList.add("highlight-validation");
      }, 2000);
    }

    this.loadGuides('update_basic_info');
  }
}
</script>
