<template>
  <div>
    <!-- Basic info block -->
    <div class="card" id="update_basic_info">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-7">
            <h4 qid="p-view-page-title" class="mb-2">
              {{ activity.name }}
            </h4>
            <div class="mb-2" v-if="!showOnTask">
              <span class="cursor-pointer task-label-open mr-2"
                    v-if="getTask('update_basic_info')"
              >
                <UpdateSingleTaskModal
                    :task="myTasks[0]"
                    @reloadContent="reloadAll"
                    :editIcon="true"
                ></UpdateSingleTaskModal>
              </span>
              <span v-if="getGuide('update_basic_info')">
                <GuideButton :guide="getGuide('update_basic_info')"></GuideButton>
              </span>
            </div>
            <span v-if="activity.users && activity.users.length" class="mr-2">
              {{ $t('table.owners') }}:
            </span>
            <span class="mr-1" v-for="user in activity.users" qid="activity-users-list">
              <Avatar :object="user" size="xs"></Avatar>
            </span>
            <span v-if="activity.tags_translated && activity.tags_translated.length" class="mr-2 ml-2">
              {{ $t('table.tags') }}:
            </span>
            <span v-if="activity.tags_translated && activity.tags_translated.length" qid="activity-tags-list">
              <span class="badge" v-for="tag in getReducedTags(activity.tags_translated)" :title="tag.name_translated">
                <font-awesome-icon icon="tag"/>
                {{ tag.name_translated | truncate(35) }}
              </span>
              <span v-if="activity.tags_translated.length > 3"
                    class="badge cursor-pointer"
                    @click="openTagsModal(activity)"
              >
                <font-awesome-icon icon="tags"/>
                + {{ activity.tags_translated.length - 3 }}
              </span>
            </span>
            <span v-if="activity.countries && activity.countries.length" class="mr-2 ml-2">
              {{ $t('table.countries') }}:
            </span>
            <span class="mr-1" v-for="country in activity.countries_with_flag" qid="p-countries-list">
              <span class="badge">
                <img v-bind:src="country.flag" height="13" class="align-baseline mr-1" /> <span>{{country.name}}</span>
              </span>
            </span>
          </div>
          <div class="col-md-5 text-right" qid="view-s-modified">
            <div class="mb-4">
              <span class="h6 ml-3">
                <ActivityEditForm
                    :object="activity"
                    :edit="edit"
                    @reloadContent="reloadContent"
                    v-if="isGranted([permissions.ACTIVITY_EDIT, permissions.ACTIVITY_EDIT_OWN], activity)"
                ></ActivityEditForm>
                <span class="btn btn-delete"
                      qid="delete-s-button"
                      v-if="!showOnTask && isGranted([permissions.ACTIVITY_DELETE, permissions.ACTIVITY_DELETE_OWN], activity)"
                      @click="showConfirmDeleteModal(activity)"
                >
                  <font-awesome-icon icon="trash-alt"/>
                </span>
              </span>
            </div>
            <div>
              <span>{{ $t('table.last_updated') }}</span>
                <span class="ml-2 text-muted small" qid="view-s-modified-date">
                {{ activity.updated_at | formatDate }}
              </span>
              <Avatar v-if="activity.updated_by"
                      :object="activity.updated_by"
                      size="xs"
                      class="ml-3 mr-2"
              ></Avatar>
              <span v-if="activity.updated_by" qid="view-s-modified-by">
                {{ activity.updated_by.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Delete Activity modal template -->
    <b-modal ref="delete-activity" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-s-title">
          {{ $t('activities.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('activities.delete.description') }} {{ itemToDelete.name }}
      </h5>
      <h5 class="text-center">
        {{ $t('activities.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideConfirmDeleteModal"
                  qid="delete-s-cancel-button"
        >
          {{ $t('activities.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDelete(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-s-submit-button"
                  v-if="isGranted([permissions.ACTIVITY_DELETE, permissions.ACTIVITY_DELETE_OWN], activity)"
        >
          {{ $t('activities.delete.submit') }}
        </b-button>
      </div>
    </b-modal>

    <ShowMoreModal
        :items="selectedItem.tags_translated"
        :show="showTagsModal"
        @closeViewModal="closeTagsModal"
        type="tags"
    ></ShowMoreModal>


  </div>
</template>

<script>
import piincHttp from '@/util/configureAxios.js'
import {config} from '@/config.js'
import ActivityEditForm from '@/views/Activities/ActivityEditForm.vue';
import Avatar from '@/components/Avatar.vue';
import ShowMoreModal from '@/components/ShowMoreModal.vue';
import UpdateSingleTaskModal from '@/views/Tasks/UpdateSingleTaskModal.vue';
import GuideButton from '@/components/guide/GuideButton.vue';

export default {
  name: 'BasicInfoBlock',
  data: function() {
    return {
      descriptionLength: config.descriptionLength,
      showFullDescription: false,
      showFullPurpose: false,
      activity: {},
      selectedSort: '',
      selectedDirection: '',
      lbTableItems: [],
      lbLoading: true,
      lbValidationShow: false,

      itemToDelete: {},
      processGroups: [],
      tags: [],
      users: [],
      error: '',
      lbForm: {
        name: '',
        article_reason: '',
        links: []
      },
      inputLink: '',
      selectedArticle: [],
      articles: [],
      selectedFiles: [],
      showArticleDescription: false,
      selectedLB: {},
      viewLBModal: false,
      editLBModal: false,
      updateValidationList: false,
      selectedLBSort: '',
      selectedLBDirection: '',
      showDescriptionInput: false,
      showPurposeInput: false,
      processEditDescription: '',
      processEditPurpose: '',
      showActivitiesTab: false,
      showAssessmentsTab: false,
      showHistoryTab: false,
      showRiskTab: false,
      showGdprTab: false,
      buttonDisabled: false,
      myTasks: [],
      myGuides: [],
      tabIndex: 0,
      selectedItem: {},
      showTagsModal: false,
    }
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    showOnTask: {},
    edit: {}
  },
  computed: {
    getClient() {
      return this.$store.state.client
    },
    getReloadContent() {
      return this.$store.state.updateContent
    },
    getReloadTasks() {
      return this.$store.state.reloadTasks
    }
  },
  watch: {
    object: function (value) {
      if (value && value.slug) {
        this.activity = value
        this.loadTasks('update_basic_info');
      }
    },
    getReloadContent: function () {
      if (!this.showOnTask) {
        if (this.$store.state.updatedObject && this.$store.state.updatedObject.id) {
          if (this.$store.state.updatedObject.id == this.activity.id && this.$store.state.updatedObject.who_am_i == this.activity.who_am_i) {
            this.activity = this.$store.state.updatedObject
          }
        }
      }
    },
    getReloadTasks: function () {
      if (!this.showOnTask) {
        this.loadTasks('update_basic_info')
      }
    },
  },
  components: {
    ActivityEditForm,
    Avatar,
    ShowMoreModal,
    UpdateSingleTaskModal,
    GuideButton
  },
  methods: {
    goToList() {
      this.$router.push('/processing-activities')
    },
    reloadAll() {
      this.loadContent()
      this.loadTasks('update_basic_info')
    },
    openTagsModal(object) {
      this.selectedItem = object
      this.showTagsModal = true
    },
    closeTagsModal() {
      this.selectedItem = {}
      this.showTagsModal = false
    },
    getReducedTags(tags) {
      let reducedTags = []
      if (tags.length > 3) {
        reducedTags = _.cloneDeep(tags);
        reducedTags = reducedTags.slice(0, 3);
      } else {
        reducedTags = tags;
      }
      return reducedTags;
    },
    getTask(code) {
      let existingTask = _.find(this.myTasks, function(object) {
        return object.code == code
      });
      return existingTask
    },
    openToolbar(object) {
      this.$store.commit('storeTaskToolbarState', true);
      this.$store.commit('storeActiveTask', object);
    },
    getGuide(code) {
      let existingGuide = _.find(this.myGuides, function(object) {
        return object.scope == code && object.type == 'Activity' && object.enabled
      });
      return existingGuide
    },
    loadGuides(code) {
      let self = this;
      let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

      piincHttp.get('guides', { params:
        {
          per_page: 1,
          language: 'en',
          type: 'Activity',
          scope: code,
          client: this.getClient.slug
        }
      }).then(function(response) {
        if (response.data.items.length) {
          self.myGuides.push(response.data.items[0])
        }
      }, function() {});
    },
    reloadContent(object) {
      this.loadTasks('update_basic_info');
      // If slug is not changed, just reload content
      if (object.slug == this.activity.slug) {
        // Remove last item in breadcrumbs, because it will be added again on load content
        this.loadContent(true);
        this.$emit('updated', object);
      } else {
        this.$store.commit('storeUpdateTasks', true);
        // If slug changed, reload state with new param
        this.$router.push({ name: 'activities.view', params: { slug: object.slug }})
      }
    },
    updateValidation(validation) {
      // Event emmited from validation component, update active validation
      this.activity.validation = validation
    },
    showConfirmDeleteModal(object) {
      this.itemToDelete = object
      this.$refs['delete-activity'].show()
    },
    hideConfirmDeleteModal() {
      this.itemToDelete = {}
      this.$refs['delete-activity'].hide()
    },
    confirmDelete(object) {
      let self = this;
      piincHttp.delete('activities/' + object.slug).then(function() {
        self.hideConfirmDeleteModal();
        self.goToList();
        self.$store.commit('storeUpdateTasks', true);
        self.$store.commit('storeActiveTask', {});
        // Trigger toastr message after modal close
        self.$toastr('success', self.$t('activities.delete.success'))
      }, function() {});
    },
    loadContent(breadcrumbPush) {
      let self = this;
      piincHttp.get('activities/' + this.activity.slug, { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.activity = response.data;
      }, function() {});
    },
    loadTasks(code) {
      let self = this;
      if (this.getClient.projectModule) {
        piincHttp.get('projects/me/tasks', { params:
          {
            per_page: 1,
            status: 'pending',
            model_type: this.activity.who_am_i,
            model_id: this.activity.id,
            code: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.myTasks = []
          if (response.data.items.length) {
            self.myTasks.push(response.data.items[0])
          }
        }, function() {});
      }
    }
  },
  created () {
    this.activity = this.object;
    if (this.getClient && this.getClient.slug) {

    }
    if (this.activity && this.activity.slug) {
      this.loadTasks('update_basic_info');
    }

    this.loadGuides('update_basic_info');

  }
}
</script>
