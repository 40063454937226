<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div>
          <b-list-group>
            <b-list-group-item
                v-for="activity in items"
                :key="activity.id"
                class="border-left-0 border-right-0 border-top border-bottom-0 pt-4 pb-4"
            >

              <ActivityActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Activity\\Models\\Activity'"/>
              <ActivityGdprActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Activity\\Models\\ActivityGdpr'"/>
              <ActivityPolicyActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Activity\\Models\\ActivityPolicy'"/>
              <ActivityDataSubjectActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Activity\\Models\\ActivityDataSubject'"/>
              <ActivityIntegrityControlActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Activity\\Models\\ActivityIntegrityControl'"/>
              <ActivityOrganizationActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Activity\\Models\\ActivityOrganization'"/>
              <ActivityRetentionRuleActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Activity\\Models\\ActivityRetentionRule'"/>
              <ActivitySystemActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Activity\\Models\\ActivitySystem'"/>
              <ActivityThirdPartyActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Activity\\Models\\ActivityThirdParty'"/>
              <ActivityValidationActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Validation\\Models\\Validation'"/>
              <ActivityRiskScenarioActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Activity\\Models\\ActivityRiskScenario'"/>
              <ActivityMeasureActivity :changes="true" :activity="activity" v-if="activity.subject_type === 'App\\Activity\\Models\\ActivityMeasure'"/>

            </b-list-group-item>

          </b-list-group>
          <hr class="mt-0"/>
        </div>
        <InfiniteLoading @infinite="loadingHandler">
          <template slot="no-results">
            <span>
              No data available.
            </span>
          </template>
        </InfiniteLoading>
      </div>
    </div>
  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import ActivityActivity from '@/components/activity/activity/Activity.vue';
  import ActivityGdprActivity from '@/components/activity/activity/ActivityGdpr.vue';
  import ActivityPolicyActivity from '@/components/activity/activity/ActivityPolicy.vue';
  import ActivityDataSubjectActivity from '@/components/activity/activity/ActivityDataSubject.vue';
  import ActivityIntegrityControlActivity from '@/components/activity/activity/ActivityIntegrityControl.vue';
  import ActivityOrganizationActivity from '@/components/activity/activity/ActivityOrganization.vue';
  import ActivityRetentionRuleActivity from '@/components/activity/activity/ActivityRetentionRule.vue';
  import ActivitySystemActivity from '@/components/activity/activity/ActivitySystem.vue';
  import ActivityThirdPartyActivity from '@/components/activity/activity/ActivityThirdParty.vue';
  import ActivityValidationActivity from '@/components/activity/activity/ActivityValidation.vue';
  import ActivityRiskScenarioActivity from '@/components/activity/activity/ActivityRiskScenario.vue';
  import ActivityMeasureActivity from '@/components/activity/activity/ActivityMeasure.vue';

  import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'ActivityHistory',
    data: function() {
      return {
        activity: {},
        items: [],
        currentPage: 1,
        totalRows: 0,
        page: 1
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      }
    },
    components: {
      ActivityActivity,
      ActivityPolicyActivity,
      ActivityDataSubjectActivity,
      ActivityIntegrityControlActivity,
      ActivityOrganizationActivity,
      ActivityRetentionRuleActivity,
      ActivitySystemActivity,
      ActivityThirdPartyActivity,
      ActivityGdprActivity,
      ActivityValidationActivity,
      InfiniteLoading,
      ActivityRiskScenarioActivity,
      ActivityMeasureActivity
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {

      }
    },
    methods: {
      loadingHandler($state) {
        let self = this
        piincHttp.get('activity-log', { params:
          {
            log: this.activity.who_am_i + '::' + this.activity.id,
            page: this.page,
            client: self.getClient.slug
          }
        }).then(({ data }) => {
          if (data.items.length) {
            self.page += 1;
            self.items.push(...data.items);
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
      }
    },
    created () {
      this.activity = this.object;
    }
  }
</script>
